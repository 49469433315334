<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4 pb-2">
        <label-view title="Интервал">
          <template v-slot:content>
            <v-menu v-model="dateFromIsOpen" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs" v-on="on"
                    :value="$tools.date(dateFrom)"
                    label="С" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
              </template>
              <v-date-picker :first-day-of-week="1" v-model="dateFrom" color="primary" show-current class="mb-4" no-title @change="dateFromIsOpen=false"/>
            </v-menu>
            <v-menu v-model="dateToIsOpen" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs" v-on="on"
                    :value="$tools.date(dateTo)"
                    label="По, включительно" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
              </template>
              <v-date-picker :first-day-of-week="1" v-model="dateTo" color="primary" show-current class="mb-4" no-title @change="dateToIsOpen=false"/>
            </v-menu>
          </template>
        </label-view>
        <label-view title="Тип платежа">
          <template v-slot:content>
            <v-list class="pa-0" nav>
              <v-list-item
                  v-for="key in Object.keys(types)" :key="key"
                  @click="typesSelected.filter((v)=>v===key).length===0 ? typesSelected.push(key) : typesSelected = typesSelected.filter((v)=>v!==key);"
                  class="mb-0"
              >
                <v-checkbox :input-value="isCheckboxChecked(key)" :value="typesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                <div>
                  <v-icon size="18" :color="types[key].color" class="mr-2">{{ types[key].icon }}</v-icon>
                  {{ types[key].name }}
                </div>
              </v-list-item>
            </v-list>
          </template>
        </label-view>
        <label-view title="Вид">
          <template v-slot:content>
            <v-btn block depressed color="silver" @click="groupByDay = !groupByDay">
              <div v-if="groupByDay">Список <v-icon class="ml-2" small> mdi-view-list-outline</v-icon></div>
              <div v-else>Группировка по дням <v-icon class="ml-2" small> mdi-group</v-icon></div>
            </v-btn>
          </template>
        </label-view>
        <label-view title="Способ расчета">
          <template v-slot:content>
            <v-list class="pa-0" nav>
              <v-list-item
                  v-for="key in Object.keys(sources)" :key="key"
                  @click="sourcesSelected.filter((v)=>v===key).length===0 ? sourcesSelected.push(key) : sourcesSelected = sourcesSelected.filter((v)=>v!==key);"
                  class="mb-0"
              >
                <v-checkbox :input-value="isCheckboxChecked(key)" :value="sourcesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                <div>
                  <v-icon size="18" color="type.color" class="mr-2">{{ sources[key].icon }}</v-icon>
                  {{ sources[key].name }}
                </div>
              </v-list-item>
            </v-list>
          </template>
        </label-view>
        <device-cash-box-select class="mt-4 mb-4" @selected="cashBoxsSelected" multiple/>
        <com-branch-autocomplete-multiple :select.sync="comBranchsSelected"/>
        <insur-select @selected="insurSelected"/>
        <v-switch class="pl-2" v-model="notAccepted" inset dense>
          <template v-slot:label>
            <div class="body-2 grey--text text--darken-3">Не подтвержден</div>
          </template>
        </v-switch>
        <v-text-field label="Клиент" v-model="userq" outlined dense hide-details="auto" class="mb-4" clearable/>
        <v-text-field label="Сумма" v-model="amount" outlined dense hide-details="auto" class="mb-4" clearable/>
        <v-text-field label="ID" v-model="id" class="mb-2" outlined dense hide-details="auto" clearable/>
      </div>
    </template>
    <template v-slot:content="{height}">
      <graphql-table-view
        title="Список"
        query='
          query Query(
            $page: Int
            $onPage: Int
            $sortBy: String
            $sortDesc: Boolean
            $types: String
            $created_ge: String
            $created_le: String
            $sources: String
            $notAccepted: Boolean
            $amount: Float
            $id: Int
            $userq: String
            $insurs: String
            $kktName: String
          ){
            Pay(
              page: $page
              onPage: $onPage
              sortBy: $sortBy
              sortDesc: $sortDesc
              types:$types
              created_ge:$created_ge
              created_le:$created_le
              sources:$sources
              notAccepted:$notAccepted
              amount:$amount
              id:$id
              userq:$userq
              insurs:$insurs
              kktName:$kktName
            ){
              id
              type
              amount
              amountPayed
              amountCash
              amountCard
              amountBankAccount
              amountAccount
              amountOms
              amountDms
              amountContract
              amountInsur
              insur{
                id
                name
              }
              comBranchName
              accountBalance
              kktName
              terminalName
              tranId
              comment
              created
              isAcceptBankAccount
              isAcceptOms
              isAcceptDms
              isAcceptContract
              user{
                id
                name
                fullName
              }
              operatorUser{
                id
                name
                fullName
                type
              }
            }
          }
        '
        :queryVars="queryVars"
        queryMainEntity="Pay"
        :headers="headersMain"
        :height="height"
        :barHeight="118"
        ref="table"
        :externalBody="groupByDay"
        dataSizeAlert
      >
        <template v-slot:header="{items}">
          <v-col cols="12" class="py-2 pl-4">
            <v-row no-gutters>
              <v-col v-for="(v,i) in Object.keys(sources)" :key="i" cols="auto" class="mr-4">
                <v-sheet class="px-2 py-1" outlined rounded>
                  <div class="caption">{{sources[v].name}}</div>
                  <div>{{$tools.price(items.reduce((a, b) =>a+$tools.parseNumber(b['amount'+v.substring(0,1).toUpperCase()+v.substring(1)]), 0))}}</div>
                </v-sheet>
              </v-col>
              <v-col cols="auto" class="mr-4">
                <v-sheet class="px-2 py-1" outlined rounded>
                  <div class="caption">Итого</div>
                  <div>{{$tools.price(items.reduce((a, b) =>a+$tools.parseNumber(b.amount), 0))}}</div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-slot:actions>
          <v-btn small depressed @click="internalInOutState.modalOpen({type:'internalIn',amount:null,comment:null})" class="mr-2">
            <v-icon size="14" :color="types.internalIn.color" class="mr-2">{{ types.internalIn.icon }}</v-icon>Приход
          </v-btn>
          <v-btn small depressed @click="internalInOutState.modalOpen({type:'internalOut',amount:null,comment:null})">
            <v-icon size="14" :color="types.internalOut.color" class="mr-2">{{ types.internalOut.icon }}</v-icon>Расход
          </v-btn>
          <dialog-view
              :title="internalInOutState.modalData==null ? '' : internalInOutState.modalData.type==='internalIn' ? 'Приход': 'Расход'"
              :open="internalInOutState.isModalOpen"
              @cancel="internalInOutState.modalClose()"
          >
            <template v-slot:content v-if="internalInOutState.modalData!==null">
              <v-form ref="internalInOutForm">
                <v-text-field label="Сумма" v-model="internalInOutState.modalData.amount" :rules="formFieldRules.required" class="mb-4" type="number" outlined ed dense hide-details="auto"/>
                <v-textarea label="Комментарий" v-model="internalInOutState.modalData.comment" :rules="formFieldRules.required" class="mb-4" rows="4" outlined dense auto-grow hide-details="auto"/>
                <!-- <v-select label="Касса" v-model="internalInOutState.modalData.cashBox" :items="cashBoxs" :rules="formFieldRules.required" outlined dense hide-details="auto" item-text="name" item-value="id"/> -->
                <device-cash-box-select class="mt-4 mb-4" @selected="(v)=>internalInOutState.modalData.kktName=v" required/>
              </v-form>
            </template>
            <template v-slot:actions>
              <v-btn text color="primary" @click="internalInOutSubmit()" :loading="internalInOutState.isLoading">ОК</v-btn>
            </template>
          </dialog-view>
        </template>
        <template v-slot:item="{item}">
          <tr @click="payId=item.id">
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            <td class="text-no-wrap">
              <v-icon size="18" class="ml-n1 mr-2" :color="types[item.type].color">{{types[item.type].icon}}</v-icon>
              {{types[item.type].name}}
            </td>
            <td class="text-no-wrap">
              <pay-sources :pay="item" compact/>
            </td>
            <td class="text-no-wrap" :data-print="item.comment">
          
              <tooltip v-if="!$tools.isEmpty(item.comment)" bottom>              
                {{$tools.truncateText(item.comment,30)}}
                <template v-slot:content>
                  <vue-markdown>{{item.comment}}</vue-markdown>
                </template>
              </tooltip>
            </td>
            <td class="text-no-wrap"><user-button-view v-if="item.operatorUser" :user="item.operatorUser" :type="item.operatorUser.type" link fullName/></td>
            <td class="text-no-wrap"><user-button-view v-if="item.user" :user="item.user" type="client" link fullName/></td>
            <td class="text-no-wrap">          
              <span v-if="item.user">{{item.user.id}}</span>
              <span v-else class="text-center">-</span>            
            </td>
            <td class="text-no-wrap">
              <pay-devices :pay="{kktName:item.kktName}"/>
            </td>
            <td class="text-no-wrap">{{item.comBranchName}}</td>
            <td v-if="item.insur" class="text-no-wrap">{{item.insur.name}}</td>
            <td v-else>-</td>
            <td class="text-no-wrap">{{item.type==="internalIn" || item.type==="internalOut" ? '' : $tools.price(item.accountBalance)}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountCash, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountCard, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountBankAccount, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountAccount, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountOms, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountDms, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountContract, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amountInsur, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.amount, '-')}}</td>
          </tr>
        </template>
        <template v-slot:body="{items,bodyHeight}">
          <v-data-table
            :headers="headersDays"
            :items="itemsRanged(items)"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="date"
            show-expand
            hide-default-footer
            fixed-header
            :height="bodyHeight"
            class="position-absolute"
            :footer-props="{'items-per-page-options': [1000000]}"
            :serverItemsLength="1000000"
            dense
          >
            <template v-slot:item="{ item, expand, isExpanded}">
              <tr @click.stop="expand(!isExpanded);" >
                <td><v-icon>{{ isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon></td>
                <td>{{ item.date }}</td>
                <td>{{ item.items.length }}</td>
                <td v-for="(v,i) in Object.keys(sources)" :key="i">{{ $tools.price(item.items.reduce((a, b) =>a+$tools.parseNumber(b['amount'+v.substring(0,1).toUpperCase()+v.substring(1)]), 0)) }}</td>
                <td>{{$tools.price(item.items.reduce((a, b) =>a+$tools.parseNumber(b.amount), 0))}}</td>
              </tr>
            </template>
            <template v-slot:expanded-item="{headers,item}">
              <tr>
                <td :colspan="headers.length" class="px-0">
                  <v-data-table
                      :headers="headersMain"
                      :items="item.items"
                      :footer-props="{'items-per-page-options': [1000000]}"
                      :serverItemsLength="1000000"
                      hide-default-footer
                      :calculate-widths="true"
                      dense
                  >
                    <template v-slot:item="{item}">
                      <tr @click="payId=item.id">
                        <td class="text-no-wrap">{{item.id}}</td>
                        <td class="text-no-wrap">
                          <v-icon size="18" class="ml-n1 mr-2" :color="types[item.type].color">{{types[item.type].icon}}</v-icon>
                          {{types[item.type].name}}
                        </td>
                        <td class="text-no-wrap">
                          <pay-sources :pay="item" compact/>
                        </td>
                        <td class="text-wrap"><v-sheet width="200" color="transparent">{{item.comment}}</v-sheet></td>
                        <td class="text-no-wrap"><user-button-view v-if="item.operatorUser" :user="item.operatorUser" :type="item.operatorUser.type" link/></td>
                        <td class="text-no-wrap"><user-button-view v-if="item.user" :user="item.user" type="client" link/></td>
                        <td class="text-no-wrap">
                          <pay-devices :pay="{kktName:item.kktName}"/>
                        </td>
                        <td class="text-no-wrap">{{item.type==="internalIn" || item.type==="internalOut" ? '' : $tools.price(item.accountBalance)}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountCash, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountCard, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountBankAccount, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountAccount, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountOms, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountDms, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountContract, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amountInsur, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.price(item.amount, '-')}}</td>
                        <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </graphql-table-view>
    </template>
    <template v-slot:footer>
      <pay :id="payId" @cancel="payId=null;$refs.table.load(true,false)"/>
    </template>
  </layout-view>
</template>

<script>
import LabelView from "@/components/LabelView";
import LayoutView from "@/components/LayoutView";
import GraphqlTableView from "@/components/GraphqlTableView";
import moment from "moment/moment";
import Api from "@/Api";
import Pay from "@/views/Pay/Pay";
import DialogView from "@/components/DialogView";
import State from "@/plugins/state";
import axios from "axios";
import {FormFieldRules} from "@/plugins/formFieldRules";
import InsurSelect from "@/views/Insur/components/InsurSelect";
import PaySources from "@/views/Pay/components/PaySources.vue";
import PayDevices from "@/views/Pay/components/PayDevices.vue";
import UserButtonView from "@/components/UserButtonView.vue";
import DeviceCashBoxSelect from "@/views/Device/component/DeviceCashBoxSelect.vue";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";


export default {
  props: ['filter'],
  components: {
    DeviceCashBoxSelect,
    UserButtonView,
    PayDevices,
    PaySources,
    InsurSelect,
    DialogView,
    Pay, 
    GraphqlTableView, 
    LayoutView,
    LabelView,
    ComBranchAutocompleteMultiple,
    Tooltip,
  },
  data: () => ({
    dateFrom:null,
    dateTo:null,
    types:Api.model.Pay.types,
    typesSelected:[],
    sources:Api.model.Pay.sources,
    sourcesSelected:[],
    notAccepted:null,
    userq:'',
    amount:null,
    id:null,
    payId:null,
    groupByDay:false,
    expanded:[],
    headersMain:[
      { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
      { text: 'Дата', value: 'created', class:'text-no-wrap', sortable: false },
      { text: 'Тип платежа', value: 'type', class:'text-no-wrap', sortable: false },
      { text: 'Способы расчета', value: 'source', class:'text-no-wrap', sortable: false },
      { text: 'Комментарий', value: 'comment', class:'text-no-wrap', sortable: false },
      { text: 'Кассир', value: 'operatorUser', class:'text-no-wrap', sortable: false },
      { text: 'Клиент', value: 'user', class:'text-no-wrap', sortable: false },
      { text: 'ID клиента', value: 'user.id', class:'text-no-wrap', sortable: false },
      { text: 'Фискальный регистратор', value: 'cashBox', class:'text-no-wrap', sortable: false },
      { text: 'Филиал', value: 'comBranchName', class:'text-no-wrap', sortable: false },
      { text: 'Наименование организации', value: 'insur.name', class:'text-no-wrap', sortable: false },
      { text: 'Баланс личного счета', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Наличные', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Банковская карта', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Расчётный счет', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Личный счёт', sortable: false, class:'text-no-wrap text-right'},
      { text: 'ОМС', sortable: false, class:'text-no-wrap text-right'},
      { text: 'ДМС', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Контракт', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Организация', sortable: false, class:'text-no-wrap text-right'},
      { text: 'Итого', value: 'amount', class:'text-no-wrap', sortable: false }
    ],
    headersDays:[
      { text: '', value: 'data-table-expand'},
      { text: 'Дата', value: 'date', align: 'start', class:'text-no-wrap'},
      { text: 'Количество', value: 'total', class:'text-no-wrap', sortable: false },
    ],

    queryVars: {
      created_ge:null,
      created_le:null,
      types:'',
      sources:'',
      insurs:null
    },

    internalInOutState:new State(),
    formFieldRules: FormFieldRules,

    reportState: new State(),
    dateFromIsOpen:false,
    dateToIsOpen:false,
    cashBox:null,
    comBranchsSelected:[]
  }),
  beforeMount(){
    this.dateFrom = this.filter.dateFrom;
    this.dateTo = this.filter.dateTo;
    this.typesSelected = this.filter.typesSelected;
    this.sourcesSelected = this.filter.sourcesSelected; 
    this.queryVars.created_ge = this.filter.dateFrom;
    this.queryVars.created_le = this.filter.dateTo;

    //if type selected filter enabled
    if (this.typesSelected.length>0) {
      this.queryVars.types = '';
      this.typesSelected.forEach(v=>{
          this.queryVars.types+=v+',';
      });
    }  
    //if sources selected filter enabled
    if (this.sourcesSelected.length>0) {
      this.queryVars.sources = '';
        this.sourcesSelected.forEach(v=>{
          this.queryVars.sources+=v+',';
      });
    }


    Object.keys(this.sources).forEach(v=>{
      this.headersDays.push({ text: this.sources[v].name, value: v, class:'text-no-wrap', sortable: false });
    });
    this.headersDays.push({ text: 'Итого', value: 'total', class:'text-no-wrap', sortable: false });
  },
  watch:{
    dateFrom(){
      this.queryVars.created_ge = moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
      this.filter.dateFrom = moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
      this.$emit('update:filter', this.filter);
      this.$refs.table.load();
    },
    dateTo(){
      this.queryVars.created_le = moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59');
      this.filter.dateTo = moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59');
      this.$emit('update:filter', this.filter);
      this.$refs.table.load();
    },
    typesSelected(){
      this.queryVars.types = '';
      this.typesSelected.forEach(v=>{
        this.queryVars.types+=v+',';
      });
      this.filter.typesSelected = this.typesSelected;
      this.$emit('update:filter', this.filter);
      this.$refs.table.load();
    },
    sourcesSelected(){
      this.queryVars.sources = '';
      this.sourcesSelected.forEach(v=>{
        this.queryVars.sources+=v+',';
      });
      this.filter.sourcesSelected = this.sourcesSelected;
      this.$emit('update:filter', this.filter);
      this.$refs.table.load();
    },
    notAccepted(){
      if(this.notAccepted){
        this.queryVars.notAccepted = true;
      } else {
        delete this.queryVars.notAccepted;
      }
      this.$refs.table.load();
    },
    amount(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.amount)){
          this.queryVars.amount = parseFloat(this.amount);
        } else {
          delete this.queryVars.amount;
        }
        this.$refs.table.load();
      });
    },
    id(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.id)){
          this.queryVars.id = parseInt(this.id);
        } else {
          delete this.queryVars.id;
        }
        this.$refs.table.load();
      });
    },
    userq(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.userq)){
          this.queryVars.userq = this.userq;
        } else {
          delete this.queryVars.userq;
        }
        this.$refs.table.load();
      });
    },
    comBranchsSelected(v){
      let r = '';
      v.forEach((v1)=>{
        r +=v1.name+',';
      });
      this.queryVars.comBranchNames = r;
      this.$refs.table.load();
    }
  },
  methods:{
    internalInOutSubmit(){
      if (this.$refs.internalInOutForm.validate()){
        this.internalInOutState.stateLoading();
        axios.post(Api.host+'/pay/internal/', this.internalInOutState.modalData)
          .then(r =>{
            this.template = r.data;
            this.internalInOutState.stateSuccess();
            this.internalInOutState.modalClose();
            this.$refs.table.load(true);
          })
          .catch(e =>{
            Api.responseError(e)
            this.internalInOutState.stateError();
          });
      }
    },

    itemsRanged(items){
      let items1 = this.$tools.copy(items);
      let dateRange = [];
      if(items1.length>0){
        let sorted = items1.sort((a,b)=>{
          let a1 = moment(a.created).unix();
          let b1 = moment(b.created).unix();
          if(a1===b1) return 0;
          return a1>b1 ? 1 : -1;
        });
        let start = moment(sorted[0].created);
        let end = moment(sorted[sorted.length-1].created);
        while (start.isSameOrBefore(end, 'day')) {
          let h = start.format('DD.MM.YYYY');
          let items2 = items1.filter(v=>moment(v.created).format('DD.MM.YYYY')===h);
          if(items2.length>0)
            dateRange.push({
              date:h,
              items:items2,
            });
          start.add(1, 'day');
        }
      }
      dateRange = dateRange.reverse();
      return dateRange;
    },

    cashBoxsSelected(v){
      if(this.$tools.isEmpty(v))
        delete this.queryVars.kktName;
      else
        this.queryVars.kktName = v;
      this.$refs.table.load();
    },
    insurSelected(v){
      let r = '';
      v.forEach((v1)=>{
        r +=v1.id+',';
      });
      this.queryVars.insurs = r;
      this.$refs.table.load();
    },
    isCheckboxChecked(key) {
      if (this.typesSelected.includes(key)||this.sourcesSelected.includes(key)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>