<template>
  <div>
    <slot v-if="state.isSuccess"/>
    <v-progress-circular v-if="state.isLoading" indeterminate color="primary" class="mx-auto my-4 d-block"/>
    <div v-if="state.isError" class="text-center py-3">
      <v-icon class="mb-2" size="36" color="grey">mdi-information-outline</v-icon>
      <div class="muted1 body-2 px-8">{{state.errorMessage}}</div>
      <v-btn depressed small class="mt-4" @click="state.stateLoading()"><v-icon class="mr-2" dense>mdi-refresh</v-icon>Повторить</v-btn>
    </div>
  </div>
</template>

<script>
import State from "@/plugins/state";

export default {
  props: ['state'],
  data: () => ({
    localState: new State(),
  }),
  watch: {
    state: {
      deep: true,
      handler() {
        this.localState = this.state;
      },
    }
  }
}
</script>
