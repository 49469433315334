<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4 pb-2">
        <label-view title="Интервал">
          <template v-slot:content>
            <v-menu v-model="dateFromIsOpen" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs" v-on="on"
                    :value="$tools.date(dateFrom)"
                    label="С" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
              </template>
              <v-date-picker :first-day-of-week="1" v-model="dateFrom" color="primary" show-current class="mb-4" no-title @change="dateFromIsOpen=false"/>
            </v-menu>
            <v-menu v-model="dateToIsOpen" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs" v-on="on"
                    :value="$tools.date(dateTo)"
                    label="По, включительно" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
              </template>
              <v-date-picker :first-day-of-week="1" v-model="dateTo" color="primary" show-current class="mb-4" no-title @change="dateToIsOpen=false"/>
            </v-menu>
          </template>
        </label-view>
        <label-view title="Тип платежа">
          <template v-slot:content>
            <v-list class="pa-0" nav>
              <v-list-item
                  v-for="key in Object.keys(types)" :key="key"
                  @click="typesSelected.filter((v)=>v===key).length===0 ? typesSelected.push(key) : typesSelected = typesSelected.filter((v)=>v!==key);"
                  class="mb-0"
              >
                <v-checkbox :input-value="isCheckboxChecked(key)" :value="typesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                <div>
                  <v-icon size="18" :color="types[key].color" class="mr-2">{{ types[key].icon }}</v-icon>
                  {{ types[key].name }}
                </div>
              </v-list-item>
            </v-list>
          </template>
        </label-view>
        <label-view title="Способ расчета">
          <template v-slot:content>
            <v-list class="pa-0" nav>
              <v-list-item
                  v-for="key in Object.keys(sources)" :key="key"
                  @click="sourcesSelected.filter((v)=>v===key).length===0 ? sourcesSelected.push(key) : sourcesSelected = sourcesSelected.filter((v)=>v!==key);"
                  class="mb-0"
              >
                <v-checkbox :input-value="isCheckboxChecked(key)" :value="sourcesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                <div>
                  <v-icon size="18" color="type.color" class="mr-2">{{ sources[key].icon }}</v-icon>
                  {{ sources[key].name }}
                </div>
              </v-list-item>
            </v-list>
          </template>
        </label-view>
        <device-cash-box-select class="mt-4 mb-4" @selected="cashBoxsSelected"/>
        <insur-select @selected="insurSelected"/>
        <v-switch class="pl-2" v-model="notAccepted" inset dense>
          <template v-slot:label>
            <div class="body-2 grey--text text--darken-3">Не подтвержден</div>
          </template>
        </v-switch>
        <v-text-field label="Клиент" v-model="userq" outlined dense hide-details="auto" class="mb-4" clearable/>
        <v-text-field label="Сумма" v-model="amount" outlined dense hide-details="auto" class="mb-4" clearable/>
        <v-text-field label="ID" v-model="id" class="mb-2" outlined dense hide-details="auto" clearable/>
      </div>
    </template>
    <template v-slot:content="{height}">
      <v-data-table
          :headers="headers"
          :items="items"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="date"
          show-expand
          hide-default-footer
          :loading="loadState.isLoading"
          :height="height"
          :serverItemsLength="10000"
        >

          <template v-slot:item="{ item, expand, isExpanded}">
              <tr>
                <td><v-icon @click.stop="expand(!isExpanded);" >{{ isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon></td>
                <td>{{ item.date }}</td>
                <td>{{ item.total }}</td>
                <td>{{$tools.price(item.totalSum )}}</td>
              </tr>
          </template>
          <template v-slot:expanded-item="{headers,item}">
            <template v-if="loadState.isSuccess">
              <tr>
                <td :colspan="headers.length">
                  <v-data-table
                  :headers="[
                    { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
                    { text: 'Тип платежа', value: 'type', class:'text-no-wrap', sortable: false },
                    { text: 'Способы расчета', value: 'source', class:'text-no-wrap', sortable: false },
                    { text: 'Комментарий', value: 'comment', class:'text-no-wrap', sortable: false },
                    { text: 'Кассир', value: 'operatorUser', class:'text-no-wrap', sortable: false },
                    { text: 'Клиент', value: 'user', class:'text-no-wrap', sortable: false },
                    { text: 'Фискальный регистратор', value: 'cashBox', class:'text-no-wrap', sortable: false },
                    { text: 'Баланс личного счета', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'Банковская карта', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'Наличные', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'Личный счёт', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'Расчётный счет', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'Контракт', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'ДМС', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'ОМС', sortable: false, class:'text-no-wrap text-right'},
                    { text: 'Сумма', value: 'amount', class:'text-no-wrap', sortable: false },
                    { text: 'Дата', value: 'created', class:'text-no-wrap', sortable: false }
                  ]"
                    :items="itemsByDate[item.date]"
                    :server-items-length="50"
                    :loading="loadState.isLoading"
                    :footer-props="{'items-per-page-options': [50, 100, 500]}"
                    class="pays"
                >
                  <template v-slot:item="{item}">
                    <tr @click="payId=item.id">
                      <td class="text-no-wrap">{{item.id}}</td>
                      <td class="text-no-wrap">
                        <v-icon size="18" class="ml-n1 mr-2" :color="types[item.type].color">{{types[item.type].icon}}</v-icon>
                        {{types[item.type].name}}
                      </td>
                      <td class="text-no-wrap">
                        <pay-sources :pay="item" compact/>
                      </td>
                      <td class="text-no-wrap">{{item.comment}}</td>
                      <td class="text-no-wrap"><user-button-view v-if="item.operatorUser" :user="item.operatorUser" :type="item.operatorUser.type" link/></td>
                      <td class="text-no-wrap"><user-button-view v-if="item.user" :user="item.user" type="client" link/></td>
                      <td class="text-no-wrap">
                        <pay-devices :pay="{kktName:item.kktName}"/>
                      </td>
                      <td class="text-no-wrap">{{item.type==="internalIn" || item.type==="internalOut" ? '' : $tools.price(item.accountBalance)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountCard)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountCash)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountAccount)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountBankAccount)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountContract)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountDms)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amountOms)}}</td>
                      <td class="text-no-wrap">{{$tools.price(item.amount)}}</td>
                      <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </tr>
            </template>
          </template>
        </v-data-table>

    </template>

  </layout-view>
</template>

<script>
import LabelView from "@/components/LabelView";
import LayoutView from "@/components/LayoutView";
// import GraphqlTableView from "@/components/GraphqlTableView";
import moment from "moment/moment";
import Api from "@/Api";
// import Pay from "@/views/Pay/Pay";
//import DialogView from "@/components/DialogView";
import State from "@/plugins/state";
import axios from "axios";
import {FormFieldRules} from "@/plugins/formFieldRules";
import InsurSelect from "@/views/Insur/components/InsurSelect";
import PaySources from "@/views/Pay/components/PaySources.vue";
import PayDevices from "@/views/Pay/components/PayDevices.vue";
import UserButtonView from "@/components/UserButtonView.vue";
import DeviceCashBoxSelect from "@/views/Device/component/DeviceCashBoxSelect.vue";

export default {
  props: ['filter'],
  components: {
    DeviceCashBoxSelect,
    UserButtonView,
    PayDevices,
    PaySources,
    InsurSelect,
    //DialogView,
    //Pay, 
    // GraphqlTableView, 
    LayoutView,
    LabelView},
  data: () => ({
    dateFrom:null,
    dateTo:null,
    types:Api.model.Pay.types,
    typesSelected:[],
    sources:Api.model.Pay.sources,
    sourcesSelected:[],
    notAccepted:null,
    userq:'',
    amount:null,
    id:null,
    payId:null,

    queryVars: {
      created_ge:null,
      created_le:null,
      types:'',
      sources:'',
      insurs:null
    },

 
    internalInOutState:new State(),
    formFieldRules: FormFieldRules,

    reportState: new State(),
    dateFromIsOpen:false,
    dateToIsOpen:false,
    cashBox:null,

    //new
    loadState:new State(),
    items: [],
    itemsByDate:[],
    singleExpand: true,
    expanded: [],
    headers:[
      { text: '', value: 'data-table-expand'},
      { text: 'Дата', value: 'date', align: 'start', class:'text-no-wrap'},
      { text: 'Количество услуг', value: 'total', class:'text-no-wrap', sortable: false },
      { text: 'Сумма', value: 'totalSum', class:'text-no-wrap', sortable: false },
    ],
    dateRange: []
  }),
  beforeMount(){
    this.dateFrom = this.filter.dateFrom;
    this.dateTo = this.filter.dateTo;
    this.typesSelected = this.filter.typesSelected;
    this.sourcesSelected = this.filter.sourcesSelected; 
    this.queryVars.created_ge = this.filter.dateFrom;
    this.queryVars.created_le = this.filter.dateTo;

    //if type selected filter enabled
    if (this.typesSelected.length>0) {
      this.queryVars.types = '';
      this.typesSelected.forEach(v=>{
          this.queryVars.types+=v+',';
      });
    }  
    //if sources selected filter enabled
    if (this.sourcesSelected.length>0) {
      this.queryVars.sources = '';
        this.sourcesSelected.forEach(v=>{
          this.queryVars.sources+=v+',';
      });
    }
    // this.load();
    // console.log('beforemount');



  },
  mount(){
    this.load();
  },
  watch:{
    dateFrom(){
      this.queryVars.created_ge = moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
      this.filter.dateFrom = moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
      this.$emit('update:filter', this.filter);
      this.load();
    },
    dateTo(){
      this.queryVars.created_le = moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59');
      this.filter.dateTo = moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59');
      this.$emit('update:filter', this.filter);
      this.load();
    },
    typesSelected(){
      this.queryVars.types = '';
      this.typesSelected.forEach(v=>{
        this.queryVars.types+=v+',';
      });
      this.filter.typesSelected = this.typesSelected;
      this.$emit('update:filter', this.filter);
      this.load();
    },
    sourcesSelected(){
      this.queryVars.sources = '';
      this.sourcesSelected.forEach(v=>{
        this.queryVars.sources+=v+',';
      });
      this.filter.sourcesSelected = this.sourcesSelected;
      this.$emit('update:filter', this.filter);
      this.load();
    },
    notAccepted(){
      if(this.notAccepted){
        this.queryVars.notAccepted = true;
      } else {
        delete this.queryVars.notAccepted;
      }
      this.load();
    },
    amount(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.amount)){
          this.queryVars.amount = parseFloat(this.amount);
        } else {
          delete this.queryVars.amount;
        }
       // this.$refs.table.load();
      });
    },
    id(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.id)){
          this.queryVars.id = parseInt(this.id);
        } else {
          delete this.queryVars.id;
        }
        this.load();
      });
    },
    userq(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.userq)){
          this.queryVars.userq = this.userq;
        } else {
          delete this.queryVars.userq;
        }
        this.load();
      });
    }
  },
  methods:{
    load() {
      this.loadState.stateLoading();

      this.dateRange = [];
      let currentDate = moment(this.dateFrom);
      while (currentDate.isSameOrBefore(this.dateTo, 'day')) {
        this.dateRange.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
      }

      Api.graphql(`
        query Query(
            $page: Int
            $onPage: Int
            $sortBy: String
            $sortDesc: Boolean
            $types: String
            $created_ge: String
            $created_le: String
            $sources: String
            $notAccepted: Boolean
            $amount: Float
            $id: Int
            $userq: String
            $insurs: String
            $kktName: String
          ){
            Pay(
              page: $page
              onPage: $onPage
              sortBy: $sortBy
              sortDesc: $sortDesc
              types:$types
              created_ge:$created_ge
              created_le:$created_le
              sources:$sources
              notAccepted:$notAccepted
              amount:$amount
              id:$id
              userq:$userq
              insurs:$insurs
              kktName:$kktName
            ){
              id
              type
              amount
              amountPayed
              amountCash
              amountCard
              amountBankAccount
              amountAccount
              amountOms
              amountDms
              amountContract
              amountInsur
              insur{
                id
                name
              }
              accountBalance
              kktName
              terminalName
              tranId
              comment
              created
              isAcceptBankAccount
              isAcceptOms
              isAcceptDms
              isAcceptContract
              user{
                id
                name
              }
              operatorUser{
                id
                name
                type
              }
            }
          }
            
      `, this.queryVars, (data)=>{
        //console.log(data);
      //   console.log(total);
      //   console.log(sum);
      //   let item = {date: this.$tools.dateTimeFormat(date,'DD.MM.YYYY'), total:total.Pay, totalSum: sum.Pay}
      // console.log(item)
      //   // let updatedItems = [];
      //   // data.Pay.forEach(i=>{
      //   //   let item = i;
      //   //   item.dateSort = this.$tools.dateTimeFormat(item.created,'DD.MM.YYYY');      
      //   //   updatedItems.push(item);
      //   // });
      //   // this.items = updatedItems;

      //     this.items.push(item);
      this.items = [];
      this.dateRange.forEach(d=>{
        let date = this.$tools.dateTimeFormat(d,'DD.MM.YYYY');
        let total = 0;
        let totalSum = 0;
        this.itemsByDate[date] = [];
        data.Pay.forEach(item=>{
          if(date==this.$tools.dateTimeFormat(item.created,'DD.MM.YYYY')){
            this.itemsByDate[date].push(item);

            total++;
            if (item.type === 'pay'||item.type === 'accountIn'||item.type === 'internalIn') {
              totalSum+= Number(item.amount);
            }
            if (item.type === 'payCancel'||item.type === 'accountOut'||item.type === 'internalOut') {
              totalSum-= Number(item.amount);
            }
          }
        })
        if (total>0){
          let item = {date: date, total:total, totalSum: totalSum};
          this.items.push(item);
        }
      });
      //
        this.loadState.stateSuccess();
      console.log(this.itemsByDate);
      }, (e)=>{
        this.loadState.stateError(e)
      });

    },
    internalInOutSubmit(){
      if (this.$refs.internalInOutForm.validate()){
        this.internalInOutState.stateLoading();
        axios.post(Api.host+'/pay/internal/', this.internalInOutState.modalData)
          .then(r =>{
            this.template = r.data;
            this.internalInOutState.stateSuccess();
            this.internalInOutState.modalClose();
            this.$refs.table.load(true);
          })
          .catch(e =>{
            Api.responseError(e)
            this.internalInOutState.stateError();
          });
      }
    },

    cashBoxsSelected(v){
      if(this.$tools.isEmpty(v))
        delete this.queryVars.kktName;
      else
        this.queryVars.kktName = v;
      this.$refs.table.load();
    },
    insurSelected(v){
      let r = '';
      v.forEach((v1)=>{
        r +=v1.id+',';
      });
      this.queryVars.insurs = r;
      this.$refs.table.load();
    },
    isCheckboxChecked(key) {
      if (this.typesSelected.includes(key)||this.sourcesSelected.includes(key)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>