<template>
  <div>
    <dialog-view
        :open="id!==null"
        :title="'Платеж #'+id"
        :loading="loadState.isLoading"
        content-full-size big @cancel="$emit('cancel')">
      <template v-slot:barActions>
        <log entity-name="Pay" :entity-id="id"/>
      </template>
      <template v-slot:header v-if="loadState.isSuccess">
        <pay-info-header :pay="pay" @updated="load()" :visitServices="visitServices"/>
      </template>
      <template v-slot:content v-if="loadState.isSuccess">
        <v-row no-gutters v-if="visitServices.length>0">
          <v-col cols="12">
            <div v-for="(visit, index) in getVisits(visitServices)" :key="visit.id">
              <v-row class="pa-0 ma-2">
                <v-col cols="6">
                  <span class="body-2 font-weight-medium">Визит #{{visit.id}}</span>
                  <v-btn color="primary" text class="ml-4" small @click="visitId=visit.id">
                    Подробнее
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right py-0"></v-col>
                <v-col cols="auto">
                  <label-view title="Статус">
                    <template v-slot:content>
                      <visit-status v-if="statuses.length>0" :status="$tools.findById(statuses, visit.status)" :isPaidFull="JSON.parse(visit.paySummary).isPaidFull" small/>
                    </template>
                  </label-view>
                </v-col>
                <v-col>
                  <label-view title="Специалист">
                    <template v-slot:content>
                      <div>
                        {{visit.userSpec.user.fullName}}
                      </div>
                      <div>
                        {{visit.userSpec.spec.name}}
                      </div>
                    </template>
                  </label-view>
                </v-col>
                <v-col cols="3">
                  <label-view title="Кабинет / Место">
                    <template v-slot:content>
                      <div>
                        {{visit.comPlace.comRoom.sid}} {{visit.comPlace.comRoom.name}}
                      </div>
                      <div>
                        {{visit.comPlace.name}}
                      </div>
                    </template>
                  </label-view>
                </v-col>
                <v-col cols="3">
                  <div>
                    <label-view title="Дата / Время">
                      <template v-slot:content>
                        <div>
                          {{$tools.dateFull(visit.begin)}}
                        </div>
                        <div>
                          {{$tools.time(visit.begin)}} - {{$tools.time(visit.end)}}
                        </div>
                      </template>
                    </label-view>
                  </div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <label-view title="Услуги">
                    <template v-slot:content>
                      <v-simple-table class="visitTable" dense>
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th class="text-center">Кол-во шт</th>
                            <th class="text-right">Цена</th>
                            <th class="text-center">Скидка</th>
                            <th class="body-2 font-weight-medium text-right">Итого</th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr v-for="visitService in visit.visitServices" :key="visitService.id">
                            <td>{{ visitService.id }}</td>
                            <td>
                              <div>{{ visitService.name }}</div>
                              <v-chip class="mt-1 mr-2" small outlined>{{visitService.duration}} мин.</v-chip>
                              <v-chip v-if="visitService.mode==='oms'" class="mt-1 mr-2" small outlined>
                                ОМС<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                              </v-chip>
                              <v-chip v-if="visitService.mode==='dms'" class="mt-1 mr-2" small outlined>
                                ДМС<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                              </v-chip>
                              <v-chip v-if="visitService.mode==='contract'" class="mt-1 mr-2" small outlined>
                                Контракт<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                              </v-chip>
                              <v-chip v-if="visitService.medPlan!==null" class="mt-1 mr-2" small outlined>
                                План лечения<v-divider class="mx-2" vertical/>{{visitService.medPlan.name}}
                              </v-chip>
                              <div v-if="visitService.comment!==null" class="mt-1">
                                <v-icon small>mdi-comment-text-outline</v-icon>
                                <div class="caption mt-n5 ml-5">{{visitService.comment}}</div>
                              </div>
                            </td>
                            <td>
                              <div class="text-center">{{visitService.count}}</div>
                            </td>
                            <td class="text-right">
                              {{ $tools.price(visitService.price) }}
                            </td>
                            <td>
                              <div class="text-center">{{$tools.price(visitService.discount)}}</div>
                            </td>
                            <td class="text-right font-weight-medium">
                              {{ $tools.price(visitService.priceTotal) }}
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </label-view>
                </v-col>
              </v-row>
              <v-divider v-if="index<getVisits(visitServices).length-1"/>
            </div>
          </v-col>
        </v-row>
      </template>
    </dialog-view>
    <visit :visitId.sync="visitId" @cancel="load()"/>
  </div>
</template>

<script>
import DialogView from "@/components/DialogView";
import LabelView from "@/components/LabelView";
import VisitStatus from "@/components/VisitStatus";
import Visit from "@/views/Visit/Visit";
import Log from "@/views/Log/Log";
import PayInfoHeader from "@/views/Pay/components/PayInfoHeader.vue";
import Api from "@/Api";
import State from "@/plugins/state";
export default {
  name:'Pay',
  components: {
    PayInfoHeader,
    Log, Visit, VisitStatus, LabelView, DialogView,
  },
  props:['id'],
  data: () => ({
    query: `
      query Query(
        $id: Int
      ){
        Pay(
          id:$id
        ){
          id
          type
          amount
          amountPayed
          amountCash
          amountCard
          amountBankAccount
          amountAccount
          amountOms
          amountDms
          amountContract
          amountInsur
          insur{
            id
            name
          }
          kktName
          terminalName
          slipCard
          tranId
          comment
          created
          cancelDateTime
          isAcceptBankAccount
          isAcceptOms
          isAcceptDms
          isAcceptContract
          user{
            id
            name
          }
          operatorUser{
            id
            name
          }
          payParent{
            id
            type
            amount
            amountPayed
            amountCash
            amountCard
            amountBankAccount
            amountAccount
            amountOms
            amountDms
            amountContract
            amountInsur
            insur{
              id
              name
            }
            kktName
            terminalName
            tranId
            comment
            created
            user{
              id
              name
            }
            operatorUser{
              id
              name
            }
          }
          payChild{
            id
            type
            amount
            amountPayed
            amountCash
            amountCard
            amountBankAccount
            amountAccount
            amountOms
            amountDms
            amountContract
            amountInsur
            insur{
              id
              name
            }
            kktName
            terminalName
            tranId
            comment
            created
            user{
              id
              name
            }
            operatorUser{
              id
              name
            }
          }
        }
        VisitService(pay_id: $id) {
          id
          name
          comment
          count
          discount
          duration
          price
          priceTotal
          mode
          medPlan{
            id
            name
          }
          insur{
            id
            name
          }
          visit {
            id
            status
            begin
            end
            paySummary
            userSpec {
              user {
                id
                fullName
              }
              spec{
                name
              }
            }
            comPlace {
              name
              comRoom {
                sid
                name
              }
            }
          }
        }
      }
    `,
    loadState:new State(),
    pay:null,
    visitServices:null,

    visitId:null,
    statuses:Api.model.Visit.statuses,
  }),
  watch: {
    id(){
      if(this.id!==null) this.load();
    }
  },
  methods:{
    load(){
      this.loadState.stateLoading();
      Api.graphql(this.query, {id:parseInt(this.id)}, (data)=>{
        this.pay = data.Pay[0];
        this.visitServices = data.VisitService;
        this.loadState.stateSuccess();
      }, (e)=>{
        this.loadState.stateError(e)
        this.$emit('loaded', [], 0);
      });
    },
    getVisits(visitServices){
      let arr = [];
      visitServices.forEach((v)=>{
        if (arr.filter(v1 => v1.id === v.visit.id).length === 0) {
          v.visit.visitServices = [];
          arr.push(v.visit);
        }
      });
      arr.forEach((a)=>{
        visitServices.forEach((v)=>{
          if (a.id===v.visit.id) {
            a.visitServices.push(v);
          }
        });
      });
      return arr;
    },
  }
}
</script>