<template>
  <layout-view navTitle="Фильтры" contentRemoveCard>
    <template v-slot:nav>
      <div class="px-4 pb-2">
        <label-view title="Интервал">
          <template v-slot:content>
            <v-menu v-model="dateFromIsOpen" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs" v-on="on"
                    :value="$tools.date(dateFrom)"
                    label="С" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
              </template>
              <v-date-picker v-model="dateFrom" :first-day-of-week="1" color="primary" show-current class="mb-4" no-title @change="dateFromIsOpen=false"/>
            </v-menu>
            <v-menu v-model="dateToIsOpen" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs" v-on="on"
                    :value="$tools.date(dateTo)"
                    label="По, включительно" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
              </template>
              <v-date-picker v-model="dateTo" :first-day-of-week="1" color="primary" show-current class="mb-4" no-title @change="dateToIsOpen=false"/>
            </v-menu>
          </template>
        </label-view>
        <device-cash-box-select class="mt-4 mb-4" @selected="(v)=>kktName=v" multiple/>
      </div>
    </template>
    <template v-slot:content>
      <progress-page v-if="reportState.isLoading"/>
      <div v-if="reportState.isSuccess" class="overflow-hidden pb-4">
        <v-row>
          <v-col cols="12">
            <v-sheet elevation="2" rounded class="d-inline-block">
                <toolbar-view title="ДДС"/>
                <v-simple-table class="reportTable">
                    <template v-slot:default>
                        <tbody>
                        <tr>
                            <td rowspan="2">Продажи</td>
                            <td colspan="2">Остатки наличных ден. средств</td>
                            <td rowspan="2">Х отчет</td>
                        </tr>
                        <tr>
                            <td>Начало периода</td>
                            <td>Конец периода</td>
                        </tr>
                        <tr>
                            <td class="text-right">{{$tools.price(salesAll,'-')}}</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.cashBegin,'-')}}</td>
                            <td class="text-right">
                              {{
                                $tools.price(
                                  reportState.modalData.cashBegin +
                                  reportState.modalData.payRefund.cash[0]-
                                  reportState.modalData.payRefund.cash[1]+
                                  reportState.modalData.payRefund.cash[2]-
                                  reportState.modalData.payRefund.cash[3]+
                                  reportState.modalData.payRefund.cash[4]-
                                  reportState.modalData.payRefund.cash[5]
                                  ,'-'
                                )
                              }}</td>
                            <td class="text-right">{{
                                $tools.price(
                                  reportState.modalData.payRefund.cash[0]-
                                  reportState.modalData.payRefund.cash[1]+
                                  reportState.modalData.payRefund.cash[2]-
                                  reportState.modalData.payRefund.cash[3]+

                                  reportState.modalData.payRefund.card[0]-
                                  reportState.modalData.payRefund.card[1]+
                                  reportState.modalData.payRefund.card[2]-
                                  reportState.modalData.payRefund.card[3]
                                  ,'-'
                                )
                            }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-sheet>
          </v-col>
          <v-col cols="auto">
            <v-sheet elevation="2" rounded max-width="360">
                <toolbar-view title="Продажи (оплата завершенных визитов)"/>
                <v-simple-table class="reportTable">
                    <template v-slot:default>
                        <tbody>
                        <tr>
                            <td class="text-left">Оплачено клиентом</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.paid,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left pl-6">Наличные</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.cash,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left pl-6">Банковская карта</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.card,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left pl-6">Личный счет</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.account,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left pl-6">Расчетный счет</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.bankAccount,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left">ОМС</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.oms.total,'-')}}</td>
                        </tr>
                        <tr v-for="(amount,name) in reportState.modalData.sales.oms.insurs" :key="'oms'+name">
                            <td class="text-left pl-6">{{name}}</td>
                            <td class="text-right">{{$tools.price(amount,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left">ДМС</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.dms.total,'-')}}</td>
                        </tr>
                        <tr v-for="(amount,name) in reportState.modalData.sales.dms.insurs" :key="'dms'+name">
                            <td class="text-left pl-6">{{name}}</td>
                            <td class="text-right">{{$tools.price(amount,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Контракт</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.contract.total,'-')}}</td>
                        </tr>
                        <tr v-for="(amount,name) in reportState.modalData.sales.contract.insurs" :key="'contract'+name">
                            <td class="text-left pl-6">{{name}}</td>
                            <td class="text-right">{{$tools.price(amount,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Организацией</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.insur.total,'-')}}</td>
                        </tr>
                        <tr v-for="(amount,name) in reportState.modalData.sales.insur.insurs" :key="'insur'+name">
                            <td class="text-left pl-6">{{name}}</td>
                            <td class="text-right">{{$tools.price(amount,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Не оплачено клиентом</td>
                            <td class="text-right">{{$tools.price(reportState.modalData.sales.unPayed,'-')}}</td>
                        </tr>
                        <tr>
                            <td class="text-left font-weight-medium">Сумма всех оказанных услуг</td>
                            <td class="text-right font-weight-medium">{{$tools.price(salesAll,'-')}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet elevation="2" rounded>
              <toolbar-view title="По операциям"/>
              <v-simple-table class="reportTable">
                  <template v-slot:default>
                      <tbody>
                      <tr>
                          <td class="text-left"></td>
                          <td>
                            <v-checkbox v-model="calcPay" @change="calc()" dense class="ma-0" hide-details>
                              <template v-slot:label>
                                <div class="black--text text-body-2">+ Оплаты</div>
                              </template>
                            </v-checkbox>
                          </td>
                          <td>
                            <v-checkbox v-model="calcPayCancel" @change="calc()" dense class="ma-0" hide-details>
                              <template v-slot:label>
                                <div class="black--text text-body-2">- Возвраты</div>
                              </template>
                            </v-checkbox>
                          </td>
                          <td>
                            <v-checkbox v-model="calcAccountIn" @change="calc()" dense class="ma-0" hide-details>
                              <template v-slot:label>
                                <div class="black--text text-body-2">+ Пополнение</div>
                              </template>
                            </v-checkbox>
                          </td>
                          <td>
                            <v-checkbox v-model="calcAccountOut" @change="calc()" dense class="ma-0" hide-details>
                              <template v-slot:label>
                                <div class="black--text text-body-2">- Снятие</div>
                              </template>
                            </v-checkbox>
                          </td>
                          <td>
                            <v-checkbox v-model="calcInternalIn" @change="calc()" dense class="ma-0" hide-details>
                              <template v-slot:label>
                                <div class="black--text text-body-2">+ Приход вн.</div>
                              </template>
                            </v-checkbox>
                          </td>
                          <td>
                            <v-checkbox v-model="calcInternalOut" @change="calc()" dense class="ma-0" hide-details>
                              <template v-slot:label>
                                <div class="black--text text-body-2">- Расход вн.</div>
                              </template>
                            </v-checkbox>
                          </td>
                          <td>Итого</td>
                      </tr>
                      <tr>
                          <td class="text-left">Наличные</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.cash" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.cash[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[0], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">Банковская карта</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.card" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.card[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[1], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">Личный счет</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.account" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.account[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[2], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">Расчетный счет</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.bankAccount" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.bankAccount[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[3], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">ОМС</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.oms" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.oms[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[4], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">ДМС</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.dms" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.dms[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[5], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">Контракт</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.contract" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.contract[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[6], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left">Организацией</td>
                          <td v-for="(v, i) in reportState.modalData.payRefund.insur" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(reportState.modalData.payRefund.insur[i], '-')}}</v-sheet>
                          </td>
                          <td class="text-right">
                            <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalVert[7], '-')}}</v-sheet>
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left font-weight-medium">Итого</td>
                          <td v-for="(v, i) in calcTotalHor" :key="i" class="text-right">
                            <v-sheet color="transparent" min-width="100" class="d-inline-block">{{$tools.price(v, '-')}}</v-sheet>
                          </td>
                        <td class="text-right">
                          <v-sheet color="transparent" min-width="100">{{$tools.price(calcTotalSum, '-')}}</v-sheet>
                        </td>
                      </tr>
                      </tbody>
                  </template>
              </v-simple-table>
            </v-sheet>
            <v-row class="mt-4">
              <v-col cols="auto">
                <v-sheet elevation="2" rounded>
                  <toolbar-view title="Прогноз продаж"/>
                  <v-simple-table class="reportTable">
                      <template v-slot:default>
                          <tbody>
                          <tr><td class="text-left">На сегодня ({{$tools.date(dateFrom)}})</td><td class="text-right">{{$tools.price(reportState.modalData.prognos.today,'-')}}</td></tr>
                          <tr><td class="text-left">На завтра ({{$tools.dateTimeFormat(dateFrom, 'DD.MM.YYYY', 'YYYY-MM-DD', 1440)}})</td><td class="text-right">{{$tools.price(reportState.modalData.prognos.nextDay,'-')}}</td></tr>
                          </tbody>
                      </template>
                  </v-simple-table>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </template>
  </layout-view>
</template>

<script>
import LabelView from "@/components/LabelView";
import LayoutView from "@/components/LayoutView";
import moment from "moment/moment";
import Api from "@/Api";
import State from "@/plugins/state";
import axios from "axios";
import ProgressPage from "@/components/ProgressPage.vue";
import ToolbarView from "@/components/ToolbarView.vue";
import DeviceCashBoxSelect from "@/views/Device/component/DeviceCashBoxSelect.vue";

export default {
  props: ['filter'],
  components: {
    DeviceCashBoxSelect,
    ToolbarView,
    ProgressPage, LayoutView,LabelView},
  data: () => ({
    dateFrom:null,
    dateTo:null,
    kktName:null,

    salesAll:0,

    calcPay:false,
    calcPayCancel:false,
    calcAccountIn:false,
    calcAccountOut:false,
    calcInternalIn:false,
    calcInternalOut:false,
    calcTotalHor:[],
    calcTotalVert:[],
    calcTotalSum:0,

    reportState: new State(),
    dateFromIsOpen: false,
    dateToIsOpen: false,
  }),
  beforeMount(){
    this.dateFrom = moment(this.filter.dateFrom).format('YYYY-MM-DD');
    this.dateTo= moment(this.filter.dateTo).format('YYYY-MM-DD');
  },
  mounted() {
    this.calcPay = true;
    this.calcPayCancel = true;
    this.calcAccountIn = false;
    this.calcAccountOut = false;
    this.calcInternalIn = false;
    this.calcInternalOut = false;
    this.reportLoad();
  },
  watch:{
    dateFrom(){
      this.filter.dateFrom = moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
      this.$emit('update:filter', this.filter);
      this.reportLoad();
    },
    dateTo(){
      this.filter.dateTo = moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59');
      this.$emit('update:filter', this.filter);
      this.reportLoad();
    },
    kktName(){
      this.reportLoad();
    },
  },
  methods:{
    reportLoad(){
      this.$tools.throttle(()=>{
        this.reportState.stateLoading();
        let url = Api.host+'/pay/report/?';
        url += 'dateFrom='+this.dateFrom+'&';
        url += 'dateTo='+this.dateTo+'&';
        if(!this.$tools.isEmpty(this.kktName))
          url += 'kktName='+this.kktName+'&';
          axios.get(url)
            .then(r=>{
              this.reportState.modalData = r.data;
              this.calc();
              this.reportState.stateSuccess();
            })
            .catch(e =>{
              this.reportState.stateError(e);
            });
      });    
    },
    calc(){
      this.calcHor();
      this.calcVer();
      this.calcTotalSum = 0;
      this.calcTotalVert.forEach(v=>{
        this.calcTotalSum +=v;
      });

      var d = this.reportState.modalData.sales;
      this.salesAll =
          d.cash+
          d.card+
          d.account+
          d.bankAccount+
          d.oms.total+
          d.dms.total+
          d.contract.total+
          d.insur.total
      ;
    },
    calcHor(){
      this.calcTotalHor = [];
      var fields = Object.entries(this.reportState.modalData.payRefund);

      let sum0 = 0;
      if(this.calcPay){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of fields)
          sum0 += value[0];
      }
      this.calcTotalHor.push(sum0);

      let sum1 = 0;
      if(this.calcPayCancel){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of fields)
          sum1 += value[1];
      }
      this.calcTotalHor.push(sum1);

      let sum2 = 0;
      if(this.calcAccountIn){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of fields)
          sum2 += value[2];
      }
      this.calcTotalHor.push(sum2);

      let sum3 = 0;
      if(this.calcAccountOut){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of fields)
          sum3 += value[3];
      }
      this.calcTotalHor.push(sum3);

      let sum4 = 0;
      if(this.calcInternalIn){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of fields)
          sum4 += value[4];
      }
      this.calcTotalHor.push(sum4);

      let sum5 = 0;
      if(this.calcInternalOut){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of fields)
          sum5 += value[5];
      }
      this.calcTotalHor.push(sum5);
    },
    calcVer(){
      this.calcTotalVert = [];

      let f1 = (d)=>{
        let sum = 0;
        if(this.calcPay) sum +=d[0];
        if(this.calcPayCancel) sum -=d[1];
        if(this.calcAccountIn) sum +=d[2];
        if(this.calcAccountOut) sum -=d[3];
        if(this.calcInternalIn) sum +=d[4];
        if(this.calcInternalOut) sum -=d[5];
        this.calcTotalVert.push(sum);
      };

      f1(this.reportState.modalData.payRefund.cash);
      f1(this.reportState.modalData.payRefund.card);
      f1(this.reportState.modalData.payRefund.account);
      f1(this.reportState.modalData.payRefund.bankAccount);
      f1(this.reportState.modalData.payRefund.oms);
      f1(this.reportState.modalData.payRefund.dms);
      f1(this.reportState.modalData.payRefund.contract);
      f1(this.reportState.modalData.payRefund.insur);
    },
  }
}
</script>