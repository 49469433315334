<template>
  <layout-view navTitle="Список">
    <template v-slot:nav>
      <content-state-view :state="devicesState">
        <v-list dense class="pa-0">
          <v-list-item-group mandatory>
            <v-list-item v-for="v in devices" :key="v.id" @click="device=v">
              <v-list-item-content>
                <v-list-item-title>{{v.name}}</v-list-item-title>
                <v-list-item-subtitle>{{v.typeName}} #{{v.id}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </content-state-view>
    </template>
    <template v-slot:content="{height}">
      <v-sheet :height="height" rounded class="overflow-hidden position-relative">
        <div v-if="device!==null">
          <v-toolbar dense elevation="0" color="transparent" max-height="48px">
            <v-toolbar-title>
              <div class="body-2 font-weight-medium">{{device.name}}</div>
              <div class="caption">{{device.typeName}}</div>
            </v-toolbar-title>
            <v-spacer/>

            <div v-if="device.typeName==='Фискальный регистратор'" class="d-inline-flex">
              <btn-question @click="pay(device)" :loading="payState.isLoading" small depressed title="Оплата" class="mr-2">
                  <template v-slot:default>Оплата</template>
                  <template v-slot:content>
                    <v-text-field v-model="payState.modalData.name" class="ma-4" dense clearable label="Название позиции" outlined hide-details="auto"/>
                    <v-text-field v-model="payState.modalData.amountCash" class="mx-4 mb-4" dense clearable label="Наличными" outlined hide-details="auto"/>
                    <v-text-field v-model="payState.modalData.amountCard" class="mx-4 mb-4" dense clearable label="Банковской картой" outlined hide-details="auto"/>
                  </template>
              </btn-question>
              <btn-question @click="payCancel(device)" :loading="payCancelState.isLoading" small depressed title="Возврат">
                  <template v-slot:default>Возврат</template>
                  <template v-slot:content>
                    <v-text-field v-model="payCancelState.modalData.name" class="ma-4" dense clearable label="Название позиции" outlined hide-details="auto"/>
                    <v-text-field v-model="payCancelState.modalData.amountCash" class="mx-4 mb-4" dense clearable label="Наличными" outlined hide-details="auto"/>
                    <v-text-field v-model="payCancelState.modalData.amountCard" class="mx-4 mb-4" dense clearable label="Банковской картой" outlined hide-details="auto"/>
                  </template>
              </btn-question>
              <v-divider vertical class="mx-3 my-1"/>
              <v-btn @click="deviceStatus(device)" :loading="deviceStatusState.isLoading" small depressed class="mr-2">Запросить статус</v-btn>
              <btn-question @click="openShift(device)" :loading="openShiftState.isLoading" small depressed class="mr-2" title="Открыть смену?">Открыть смену</btn-question>
              <btn-question @click="xReport(device)" :loading="xReportState.isLoading" small depressed class="mr-2" title="Отчет без гашения (X отчет)?">Отчет без гашения (X отчет)</btn-question>
              <btn-question @click="zReport(device)" :loading="zReportState.isLoading" small depressed title="Отчет о закрытии смены (Z отчет)?">Отчет о закрытии смены (Z отчет)</btn-question>
            </div>
            <div v-if="device.typeName==='Эквайринговый терминал'" class="d-inline-flex">
              <btn-question @click="payCard(device)" :loading="payCardState.isLoading" small depressed title="Оплата" class="mr-2">
                  <template v-slot:default>Оплата</template>
                  <template v-slot:content>
                      <v-text-field v-model="payCardState.modalData.amountCard" class="mx-4 my-4" dense clearable label="Сумма" outlined hide-details="auto"/>
                  </template>
              </btn-question>
              <btn-question @click="payCardCancel(device)" :loading="payCardCancelState.isLoading" small depressed title="Возврат">
                  <template v-slot:default>Возврат</template>
                  <template v-slot:content>
                      <v-text-field v-model="payCardCancelState.modalData.amountCard" class="mx-4 my-4" dense clearable label="Сумма" outlined hide-details="auto"/>
                  </template>
              </btn-question>
              <v-divider vertical class="mx-3 my-1"/>
              <btn-question @click="report(device)" :loading="reportState.isLoading" small depressed class="mr-2" title="Итоги дня?">Итоги дня</btn-question>
              <btn-question @click="closeTerminalShift(device)" :loading="closeTerminalShiftState.isLoading" small depressed class="mr-2" title="Закрыть смену?">Закрыть смену</btn-question>
            </div>
          </v-toolbar>
        </div>
      </v-sheet>
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import LayoutView from "@/components/LayoutView.vue";
import ContentStateView from "@/components/ContentStateView.vue";
import Kkm from "@/Kkm";
import BtnQuestion from "@/components/btnQuestion.vue";
import axios from "axios";
import Api from "@/Api";

export default {
  components: {BtnQuestion, ContentStateView,LayoutView},
  data: () => ({
    kkm: new Kkm(),

    devicesState: new State(),
    devices:[],
    device:null,

    openShiftState: new State(),
    deviceStatusState: new State(),
    xReportState: new State(),
    zReportState: new State(),
    payState: new State(),
    payCancelState: new State(),
    payCardState: new State(),
    payCardCancelState: new State(),

    reportState: new State(),
    closeTerminalShiftState: new State(),
  }),
  mounted(){
    this.payState.modalData={};
    this.payCancelState.modalData={};
    this.payCardState.modalData={};
    this.payCardCancelState.modalData={};
    this.devicesState.stateLoading(()=>{
      this.kkm.devices((res)=>{
        this.devices = res;
        this.device = this.devices[0];
        this.devicesState.stateSuccess();
      }, e=>{
        this.devicesState.stateError(e);
      });
    });
  },
  methods: {
    openShift(device) {
      this.openShiftState.stateLoading(()=>{
        axios.get(Api.host+'/user/status/')
          .then((res)=> {
            axios.get(Api.host + '/user/' + res.data.id + '/')
              .then((res) => {

                this.kkm.openShift(
                    device,
                    res.data.lastName + ' ' + res.data.firstName,
                    res.data.userProfile.inn,
                    ()=>{
                  this.$store.commit('alertSuccess', "Смена открыта");
                  this.openShiftState.stateSuccess();
                }, e=>{
                  this.openShiftState.stateError(e);
                  this.$store.commit('alertError', e);
                });

              }).catch(() => this.openShiftState.stateInit());
          }).catch(()=>this.openShiftState.stateInit());
      });
    },
    deviceStatus(device) {
      this.deviceStatusState.stateLoading(()=>{
        this.kkm.deviceStatus(device, (res)=>{
          this.$store.commit('alertSuccess', 'Название: '+device.name+'\n'+res);
          this.deviceStatusState.stateSuccess();
        }, e=>{
          this.deviceStatusState.stateError(e);
        });
      });
    },
    xReport(device) {
      this.xReportState.stateLoading(()=>{
        this.kkm.xReport(device, ()=>{
          this.xReportState.stateSuccess();
          this.$store.commit('alertSuccess', 'Команда отправлена на устройство');
        }, e=>{
          this.xReportState.stateError();
          this.$store.commit('alertError', e);
        });
      });
    },
    zReport(device) {
      this.zReportState.stateLoading(()=>{
        this.kkm.zReport(device, ()=>{
          this.zReportState.stateSuccess();
          this.$store.commit('alertSuccess', 'Команда отправлена на устройство');
        }, e=>{
          this.zReportState.stateError();
          this.$store.commit('alertError', e);
        });
      });
    },

    pay(device) {
      this.payState.stateLoading();
      axios.get(Api.host+'/user/status/')
        .then((res)=> {
          axios.get(Api.host + '/user/' + res.data.id + '/')
            .then((res) => {
              this.kkm.payRegister(
                device,
                res.data.lastName + ' ' + res.data.firstName,
                res.data.userProfile.inn,
                this.$tools.parseNumber(this.payState.modalData.amountCash),
                this.$tools.parseNumber(this.payState.modalData.amountCard),
                [
                  {
                    name:this.payState.modalData.name,
                    count:1,
                    price:this.$tools.parseNumber(this.payState.modalData.amountCash)+this.$tools.parseNumber(this.payState.modalData.amountCard),
                    priceTotal:this.$tools.parseNumber(this.payState.modalData.amountCash)+this.$tools.parseNumber(this.payState.modalData.amountCard),
                    tax:-1,
                    type:'service'
                  }
                ],
                ()=>{
                  this.payState.stateSuccess();
                  this.$store.commit('alertSuccess', 'Команда отправлена на устройство');
                }, e=>{
                  this.payState.stateError();
                  this.$store.commit('alertError', e);
                });

            }).catch(() => this.payState.stateError());
        }).catch(()=>this.payState.stateError());

    },

    payCancel(device) {
      this.payCancelState.stateLoading();
      axios.get(Api.host+'/user/status/')
        .then((res)=> {
          axios.get(Api.host + '/user/' + res.data.id + '/')
            .then((res) => {
              this.kkm.payRegisterCancel(
                device,
                res.data.lastName + ' ' + res.data.firstName,
                res.data.userProfile.inn,
                this.$tools.parseNumber(this.payCancelState.modalData.amountCash),
                  this.$tools.parseNumber(this.payCancelState.modalData.amountCard),
                [
                  {
                    name:this.payCancelState.modalData.name,
                    count:1,
                    price:this.$tools.parseNumber(this.payCancelState.modalData.amountCash)+this.$tools.parseNumber(this.payCancelState.modalData.amountCard),
                    priceTotal:this.$tools.parseNumber(this.payCancelState.modalData.amountCash)+this.$tools.parseNumber(this.payCancelState.modalData.amountCard),
                    tax:-1,
                    type:'service'
                  }
                ],
                ()=>{
                  this.payCancelState.stateSuccess();
                  this.$store.commit('alertSuccess', 'Команда отправлена на устройство');
                }, e=>{
                  this.payCancelState.stateError();
                  this.$store.commit('alertError', e);
                });

            }).catch(() => this.payCancelState.stateError());
        }).catch(()=>this.payCancelState.stateError());

    },

    payCard(device){
      this.payCardState.stateLoading();
      this.kkm.payCard(device, this.payCardState.modalData.amountCard,
        ()=>{
          this.payCardState.stateSuccess();
          this.$store.commit('alertSuccess', 'Команда отправлена на устройство');
        }, e=>{
          this.payCardState.stateError();
          this.$store.commit('alertError', e);
        });
    },
    payCardCancel(device){
      this.payCardCancelState.stateLoading();
      this.kkm.payCardCancel(device, this.payCardCancelState.modalData.amountCard,
        ()=>{
          this.payCardCancelState.stateSuccess();
          this.$store.commit('alertSuccess', 'Команда отправлена на устройство');
        }, e=>{
          this.payCardCancelState.stateError();
          this.$store.commit('alertError', e);
        });
    },

    report(device) {
      this.reportState.stateLoading(()=>{
        this.kkm.report(device, (res)=>{
          this.reportState.stateSuccess();
          this.$store.commit('alertSuccess', res.Slip);
        }, e=>{
          this.reportState.stateError();
          this.$store.commit('alertError', e);
        });
      });
    },
    closeTerminalShift(device) {
      this.closeTerminalShiftState.stateLoading(()=>{
        this.kkm.closeTerminalShift(device, (res)=>{
          this.closeTerminalShiftState.stateSuccess();
          this.$store.commit('alertSuccess', res.Slip);
        }, e=>{
          this.closeTerminalShiftState.stateError();
          this.$store.commit('alertError', e);
        });
      });
    }
  }
}
</script>