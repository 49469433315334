<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabSelected" background-color="transparent">
        <v-tab>Операции</v-tab>
        <v-tab>Операции по дням</v-tab>
        <v-tab>Отчет</v-tab>
        <v-tab>Кассовое оборудование</v-tab>
      </v-tabs>
    </portal>
    <pays v-if="tabSelected===0" :filter.sync="filter"/>
    <pays-by-day v-if="tabSelected===1" :filter.sync="filter"/>
    <pays-report v-if="tabSelected===2" :filter.sync="filter"/>
    <devices v-if="tabSelected===3" :filter.sync="filter"/>
  </div>
</template>

<script>
import Pays from "@/views/Pay/Pays";
import PaysByDay from "@/views/Pay/PaysByDay";
import Devices from "@/views/Device/Devices.vue";
import PaysReport from "@/views/Pay/PaysReport.vue";
import moment from "moment/moment";

export default {
  components: {PaysReport, Devices, Pays, PaysByDay},
  data:()=>({
    tabSelected:null,
    filter: {
      dateFrom:moment().subtract(1, "day").format('YYYY-MM-DD 00:00:00'),
      dateTo:moment().format('YYYY-MM-DD 23:59:59'),
      typesSelected:[],
      sourcesSelected:[],
    }
  })
}
</script>


