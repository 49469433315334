<template>
  <v-autocomplete
      v-model="deviceName"
      :items="devices"
      label="Фискальный регистратор"
      outlined
      dense
      hide-details
      :multiple="typeof multiple!=='undefined'"
      :rules="typeof required !== 'undefined' ? formFieldRules.required : []"
  >
    <template v-slot:append>
      <v-sheet v-if="devicesLoadState.isLoading" width="24" height="24" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" size="16" width="2" indeterminate/>
      </v-sheet>
    </template>
    <template v-slot:no-data>
      <div v-if="devicesLoadState.isLoading"></div>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>{{devicesLoadState.isLoading ? '' : 'Не найдено'}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <v-list-item-content style="width:220px">
        <v-row>
          <v-col><div class="v-list-item__title d-inline-block pt-1">{{item}}</div></v-col>
        </v-row>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{item}">
      <v-chip class="full-width mt-2" small style="width:95%">
        <v-row no-gutters class="align-center">
          <v-col class="text-overflow">{{item}}</v-col>
          <v-col cols="auto"><v-icon small @click.stop="deviceName=Array.isArray(deviceName) ? deviceName.filter((v)=>v!==item) : null">mdi-close-circle</v-icon></v-col>
        </v-row>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import {FormFieldRules} from "@/plugins/formFieldRules";

export default {
  props: ['required','multiple'],
  data: () => ({
    devicesLoadState: new State(),
    devices:[],
    deviceName:null,
    formFieldRules: FormFieldRules,
  }),
  watch:{
    deviceName(){
      if (Array.isArray(this.deviceName)) {
        this.$emit('selected', this.deviceName.join(",")); 
      } else {
        this.$emit('selected', this.deviceName);
      }
    }
  },
  mounted() {
    this.devicesLoadState.stateLoading();
    axios.get(Api.host+'/pay/cashBox/')
        .then(r =>{
          this.devices = r.data;
          this.devicesLoadState.stateSuccess();
        })
        .catch(e =>{
          Api.responseError(e)
          this.devicesLoadState.stateError();
        });
  },
}
</script>
