<template>
  <v-row>
    <v-col cols="auto" class="pb-0">
      <v-sheet min-width="200" color="transparent">
        <label-view title="Тип">
          <div class="d-flex mr-4">
            <v-icon size="18" class="d-flex mr-2" :color="Api.model.Pay.types[pay.type].color">{{ Api.model.Pay.types[pay.type].icon }}</v-icon>
            <div class="d-flex">{{Api.model.Pay.types[pay.type].name}}</div>
          </div>
        </label-view>
      </v-sheet>
    </v-col>
    <v-col cols="auto" class="pb-0">
      <v-sheet min-width="200" color="transparent">
        <label-view title="Способы расчета">
          <pay-sources :pay="pay"/>
          <v-divider class="my-1"/>
          <div v-if="pay.amountPayed-pay.amount>0">Сдача: {{$tools.price(pay.amountPayed-pay.amount)}}</div>
          <div>Сумма: {{$tools.price(pay.amount)}}</div>
        </label-view>
      </v-sheet>
    </v-col>
    <v-col cols="auto">
      <label-view title="Кассовое оборудование">
        <div v-if="$tools.isEmpty(pay.kktName) && $tools.isEmpty(pay.terminalName)">Не использовано</div>
        <pay-devices v-else :pay="pay"/>
      </label-view>
    </v-col>
    <v-col class="pb-0">
      <field-view
          label="Комментарий" :value="pay.comment"
          :url="'/api/pay/pay/'+pay.id+'/'" field="comment" type="textarea"
          class="mb-4"
      />
    </v-col>
    <v-col cols="auto" class="pb-0">
      <v-sheet width="200" color="transparent">
        <field-view
            label="Создано" :value="pay.created"
            :url="'/api/pay/pay/'+pay.id+'/'" field="created" type="datePicker"
            class="mb-4"
        />
        <field-view
            v-if="!$tools.isEmpty(pay.cancelDateTime)"
            label="Отменено" :value="pay.cancelDateTime"
            :url="'/api/pay/pay/'+pay.id+'/'" field="cancelDateTime" type="datePicker"
        />
      </v-sheet>
    </v-col>
    <v-col cols="12">
      <v-sheet v-if="pay.payParent!==null" rounded outlined class="px-4 pb-4 mb-4">
        <div class="font-weight-medium mb-2">Родительский платеж #{{pay.payParent.id}}</div>
        <pay-info-header :pay="pay.payParent"/>
        <v-btn color="grey lighten-3" small depressed>Просмотр</v-btn>
      </v-sheet>
      <v-sheet v-if="pay.payChild!==null" rounded outlined class="pa-4 mb-4">
        <div class="font-weight-medium mb-2">Дочерний платеж #{{pay.payChild.id}}</div>
        <pay-info-header :pay="pay.payChild"/>
        <v-btn color="grey lighten-3" small depressed>Просмотр</v-btn>
      </v-sheet>
      <v-btn
          v-if="pay.type==='pay'"
          @click="payCancelState.modalOpen();payCancelState.stateInit()"
          color="error" class="mr-2" small depressed>
        <v-icon class="pr-2" small>mdi-arrow-u-down-right</v-icon>Отмена оплаты
      </v-btn>
      <dialog-view
          :open="payCancelState.isModalOpen"
          title="Отмена оплаты"
          @cancel="payCancelState.modalClose()"
          content-full-size
      >
        <template v-slot:content>
          <div v-if="payCancelState.isInit">
            <!-- <alert-view v-if="!$tools.isEmpty(devicesError)" :text="devicesError+'\n Расчет наличными и банковской картой недоступны'" icon="mdi-information-outline"/> -->
            <div>
              <alert-view v-if="payCancelAccountIn"
                icon="mdi-information-outline"
                :text="
                  '- Услуги будут отвязаны, их статус будет изменен на не оплачено\n'+
                  '- При оплате наличными или картой не будет создан чек возврат продажи\n'+
                  '- При оплате картой не будет необходимо в ручную отменить операцию в терминале\n'+
                  '- При оплате через личный счет эти средства будут перемещены на личный счет\n'+
                  '- При оплате наличными/картой/рассчетным счетом/организацией будет создана дополнительная операция пополнения личного счета без чека'
                "
                left class="mx-4"
              />
              <alert-view v-else
                icon="mdi-information-outline"
                :text="
                  '- Услуги будут отвязаны, их статус будет изменен на не оплачено\n'+
                  '- При оплате наличными или картой будет создан чек возврат продажи\n'+
                  '- При оплате картой будет необходимо в ручную отменить операцию в терминале\n'+
                  '- При оплате через личный счет эти средства будут перемещены на личный счет'
                "
                left class="mx-4"
              />
              <v-textarea v-model="payCancelComment" label="Комментарий" rows="4" class="mt-4 mx-4 mb-4" hide-details outlined dense/>
              <v-checkbox v-model="payCancelAccountIn" class="mx-4 mb-4" label="Вернуть средства (наличные/карта/рассчетный счет/организацией) на личный счет" dense hide-details :readonly="parseInt(pay.amountAccount)>0 ? true : false"/>
              <v-divider/>
              <div class="overflow-hidden">
                <div class="mx-n2">
                  <alert-view v-if="!$tools.isEmpty(devicesError)" :text="devicesError+'\n Расчет наличными и банковской картой недоступны'" icon="mdi-information-outline"/>
                  <v-expansion-panels v-else accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Кассовое оборудование</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>
                          <v-col cols="12" class="pt-2">
                            <v-select
                                label="Фискальный регистратор"
                                v-model="cashBox"
                                :items="cashBoxes"
                                outlined dense hide-details item-text="name" item-value="id">
                              <template v-slot:selection="{item}">
                                <div class="my-2">
                                  <div class="body-2 font-weight-medium">{{item.name}}</div>
                                </div>
                              </template>
                              <template v-slot:item="{item}">
                                <div class="my-2">
                                  <div class="body-2 font-weight-medium">{{item.name}}</div>
                                </div>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-select
                                label="Эквайринговый терминал"
                                v-model="terminal"
                                :items="terminals"
                                outlined dense hide-details item-text="name" item-value="id">
                              <template v-slot:selection="{item}">
                                <div class="my-2">
                                  <div class="body-2 font-weight-medium">{{item.name}}</div>
                                </div>
                              </template>
                              <template v-slot:item="{item}">
                                <div class="my-2">
                                  <div class="body-2 font-weight-medium">{{item.name}}</div>
                                </div>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </div>
          <div v-if="payCancelState.isLoading">
            <div v-if="payCancelState.step===0" class="pb-4">
              <progress-page icon="mdi-account"/>
              <div class="text-center mt-n4">Сбор информации о кассире</div>
            </div>
            <div v-if="payCancelState.step===1" class="pb-4">
              <progress-page icon="mdi-credit-card-outline"/>
              <div class="text-center mt-n4">Производиться возврат платежа банковской картой<br/>через "{{terminal.name}}"</div>
              <div class="text-center mt-4">
                <div class="d-inline-block text-left">
                  <div>Слип чек:</div>
                  <div class="caption" v-html="$tools.nl2br(pay.slipCard)"></div>
                </div>
              </div>
            </div>
            <div v-if="payCancelState.step===2" class="pb-4">
              <progress-page icon="mdi-cash-register"/>
              <div class="text-center mt-n4">Производиться регистрация платежа<br/>через "{{cashBox.name}}"</div>
            </div>
            <div v-if="payCancelState.step===3" class="pb-4">
              <progress-page icon="mdi-content-save"/>
              <div class="text-center mt-n4">Сохранение платежа</div>
            </div>
          </div>
          <div v-if="payCancelState.isSuccess" class="pb-4">
            <v-sheet class="position-relative d-flex align-center ma-auto" :width="96" :height="96" color="transparent">
              <v-icon class="position-absolute d-block mx-auto" style="margin-left:calc(50% - 27px)!important" color="success" size="54">mdi-check-circle</v-icon>
            </v-sheet>
            <div class="text-center mt-n4">Отмена платежа успешно выполнена</div>
            <div class="mx-4 mt-4">
              <v-btn @click="payCancelState.modalClose()" block depressed color="primary">OK</v-btn>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-btn v-if="payCancelState.isInit && $tools.isEmpty(devicesError) || payCancelAccountIn" text color="error" @click="payCancel()" :loading="payCancelState.isLoading">Отменить оплату</v-btn>
        </template>
      </dialog-view>
      <user-button-view :user="pay.operatorUser" :type="pay.operatorUser.type" color="grey lighten-3" class="mr-2"/>
      <user-button-view :user="pay.user" type="client" color="grey lighten-3"/>
      <alert-view
          v-if="pay.type==='payCancel'"
          icon="mdi-information-outline"
          :text="'Платеж отменен, услуги отвязаны.\nСтатусы услуг изменены на не оплачено.\nЕсли была оплата наличными или картой\nбыл создан чек возврат продажи,\nбыло необходимо в ручную\nотменить операцию в терминале.\nЕсли была оплата через личный счет,\nэти средства были возвращены на личный счет'"
      />
    </v-col>
  </v-row>
</template>

<script>
import LabelView from "@/components/LabelView.vue";
import PayDevices from "@/views/Pay/components/PayDevices.vue";
import PaySources from "@/views/Pay/components/PaySources.vue";
import Api from "@/Api";
import PayInfoHeader from "@/views/Pay/components/PayInfoHeader.vue";
import State from "@/plugins/state";
import DialogView from "@/components/DialogView.vue";
import UserButtonView from "@/components/UserButtonView.vue";
import axios from "axios";
import AlertView from "@/components/AlertView.vue";
import ProgressPage from "@/components/ProgressPage.vue";
import Kkm from "@/Kkm";
import FieldView from "@/components/FieldView.vue";

export default {
  name:'PayInfoHeader',
  computed: {
    Api() {
      return Api
    }
  },
  components: {
    FieldView,
    ProgressPage, AlertView, UserButtonView, DialogView, PayInfoHeader, PaySources, PayDevices, LabelView},
  props:['pay','visitServices'],
  data:()=>({
    payCancelState: new State(),
    payCancelType:null,
    payCancelComment:null,
    payCancelAccountIn:false,

    kkm: new Kkm(),
    devicesError:null,
    cashBox:null,
    cashBoxes:[],
    terminals:[],
    terminal:null,
  }),
  mounted() {
    this.payCancelType='account';
    this.payCancelComment=null;
    this.payCancelAccountIn= parseInt(this.pay.amountAccount)>0 ? true : false;
    this.kkm.devices((res)=>{
      this.cashBoxes = res.filter(v=>v.typeName==='Фискальный регистратор');
      this.cashBox = this.cashBoxes[0];

      this.terminals = res.filter(v=>v.typeName==='Эквайринговый терминал');
      this.terminal = this.terminals[0];

      this.cashBoxes.forEach(v1=>{
        this.kkm.deviceStatusSessionError(v1, (res)=>{
          if(res!==null)
            this.devicesError = res+' "'+v1.name+'"';
        }, e=>{
          this.devicesError = e+' "'+v1.name+'"';
        });
      });
    }, e=>{
      this.devicesError = e;
    });
  },
  methods:{
    loadUser(success){
      this.payCancelState.step = 0;
      axios.get(Api.host+'/user/status/')
          .then((res)=> {
            axios.get(Api.host + '/user/' + res.data.id + '/')
                .then((res) => {
                  success(res);
                }).catch(() => this.payCancelState.stateInit());
          }).catch(()=>this.payCancelState.stateInit());
    },
    payCancel(){
      /*
      let amountCashCard = this.$tools.parseNumber(this.pay.amountCash)+this.$tools.parseNumber(this.pay.amountCard);

      let arr = [];
      arr.push({
        name: 'Отмена оплаты #'+this.pay.id,
        count: 1,
        price: amountCashCard,
        priceTotal: amountCashCard,
        tax: -1,
        type: 'service'
      });
       */
      let arr = [];
      this.visitServices.forEach(v => {
        arr.push({
          name: v.name + (this.$tools.isEmpty(v.code) ? '' : ' (' + v.code + ')'),
          count: v.count,
          price: v.price,
          priceTotal: v.priceTotal,
          tax: -1,
          type: 'service'
        });
      });

      let amountCashCard = this.$tools.parseNumber(this.pay.amountCash)+this.$tools.parseNumber(this.pay.amountCard);
      if(amountCashCard>0){
        let m = amountCashCard/this.pay.amount;
        if(m>0 && m<1)
          arr.forEach(v => {
            v.priceTotal = this.$tools.parseNumber(v.priceTotal*m, 0);
          });
      }

      this.payCancelState.stateLoading(() => {
        if(amountCashCard===0 || this.payCancelAccountIn===true){
          this.payCancelState.step = 3;
          axios.post(Api.host+'/pay/pay/'+this.pay.id+'/cancel/', {
            comment:this.payCancelComment,
            accountIn: this.payCancelAccountIn
          })
              .then(()=>{
                this.payCancelState.stateSuccess();
                this.$emit('updated');
              })
              .catch((e)=>{
                this.payCancelState.stateInit();
                this.$store.commit('alertError', e.response.data.message);
              });
        } else {
          this.loadUser((res) => {
            if(this.$tools.parseNumber(this.pay.amountCard)>0){

              this.payCancelState.step = 1;
              this.kkm.payCardCancel(
                  this.terminal,
                  this.$tools.parseNumber(this.pay.amountCard),
                  () => {

                    this.payCancelState.step = 2;
                    this.kkm.payRegisterCancel(
                        this.cashBox,
                        res.data.lastName + ' ' + res.data.firstName,
                        res.data.userProfile.inn,
                        this.pay.amountCash,
                        this.pay.amountCard,
                        arr,
                        () => {

                          this.payCancelState.step = 3;
                          axios.post(Api.host+'/pay/pay/'+this.pay.id+'/cancel/', {
                            comment:this.payCancelComment,
                            accountIn: this.payCancelAccountIn
                          })
                              .then(()=>{
                                this.payCancelState.stateSuccess();
                                this.$emit('updated');
                              })
                              .catch((e)=>{
                                this.payCancelState.stateInit();
                                this.$store.commit('alertError', e.response.data.message);
                              });

                        }, e => {
                          this.payCancelState.stateInit();
                          this.$store.commit('alertError', 'Фискальный регистратор: ' + e);
                        });

                  }, e => {
                    this.payCancelState.stateInit();
                    this.$store.commit('alertError', 'Эквайринговый терминал: ' + e);
                  });
            } else {
              this.payCancelState.step = 2;
              this.kkm.payRegisterCancel(
                  this.cashBox,
                  res.data.lastName + ' ' + res.data.firstName,
                  res.data.userProfile.inn,
                  this.pay.amountCash,
                  this.pay.amountCard,
                  arr,
                  () => {
                    this.payCancelState.step = 3;
                    axios.post(Api.host+'/pay/pay/'+this.pay.id+'/cancel/', {
                      comment:this.payCancelComment,
                      accountIn: this.payCancelAccountIn
                    })
                        .then(()=>{
                          this.payCancelState.stateSuccess();
                          this.$emit('updated');
                        })
                        .catch((e)=>{
                          this.payCancelState.stateInit();
                          this.$store.commit('alertError', e.response.data.message);
                        });
                  }, e => {
                    this.payCancelState.stateInit();
                    this.$store.commit('alertError', 'Фискальный регистратор: ' + e);
                  });
            }
          });
        }
      });
    }
  }
}
</script>